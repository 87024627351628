<template lang="pug">
  .page.page-photo
    .widget.widget--screen-size
      img.page-photo__cover(
        src="@/assets/img/photos-bg.jpg")
      .page-photo__footer
        img.page-photo__decor(
          src="@/assets/illustrations/flower-branch-dark.svg")
        p.page-photo__text(v-html="$t('photos.text_1')")
</template>

<script>
export default {
  name: 'Index'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .page-photo {

    &__cover {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__footer {
      position: absolute;
      left: 0;
      right: 0;
      top: 8rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      opacity: 0;
      animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
    }

    &__decor {
      width: 8.5rem;
      margin-bottom: 1rem;
    }

    &__text {
      text-align: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-family: 'History Pro';
      line-height: 2.2rem;
      white-space: nowrap;
    }
  }
</style>
